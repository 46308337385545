<template>
  <div class="login-wrapper">
    <div class="_img">
      <img src="../status/img/me_line.png" />
    </div>
    <div class="_login_form">
      <div class="_window">
        <div class="_title _img"><img src="../status/img/login_title_1.png" /></div>
        <div class="_img" style="margin-top: -0.4rem"><img src="../status/img/login_title_2.png" /></div>
        <div class="_form_item">
          <van-row>
            <van-col span="14">
              <input class="_input" type="text" maxlength="11" placeholder="请输入手机号" v-model="userMobile">
            </van-col>
            <van-col span="10">
              <span class="_send" @click="getCode" v-if="!dis">{{isFirst ? '发送验证码' : '重新发送'}} </span>
              <span class="_send" v-else>已发送 ({{count}})</span>
            </van-col>
          </van-row>
        </div>
        <div class="_img" style="margin-top: 0.4rem"><img src="../status/img/login_title_3.png" /></div>
        <div class="_form_item">
          <van-row>
            <van-col span="24">
              <input class="_input" type="text" maxlength="4" placeholder="请输入验证码" v-model="userCode">
            </van-col>
          </van-row>
        </div>
        <div class="_agreen" @click="isAgreen = !isAgreen">
          <img src="../status/img/agreen_1.png" v-if="!isAgreen" />
          <img src="../status/img/agreen_2.png" v-else />
          <img src="../status/img/agreen_2.png" style="position: absolute; top: -9999px; left: -9999px; opacity: 0;" />
        </div>
        <div class="_btn"><img src="../status/img/dialog_btn.png" @click="onClickLogin" /></div>
      </div>
    </div>
    <div class="_img">
      <img src="../status/img/login_fot.png" />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
export default {
  mixins: [   ],
  components: {},
  data() {
    return {
      userMobile: "",
      userCode: "",
      dis: false,
      isFirst: true,
      timer: null, //设置计时器,
      count: "",
      isAgreen: false,
    };
  },
  created() {
  },

  mounted() {
  },

  methods: {
    onClickLogin() {
      if(!this.userMobile) {
        Toast('请输入手机号码！');
        return;
      }
      if(!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.userMobile)){
        Toast('请输入正确的手机号码！');
        return;
      }
      if(!this.userCode) {
        Toast('请输入验证码！');
        return;
      }
      if(this.userCode.length < 4) {
        Toast('请输入正确的验证码！');
        return;
      }
      this.ajaxLogin();
    },

    async getCode() {
      if(!this.userMobile) {
        Toast('请输入手机号码');
        return;
      }
      if(!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.userMobile)){
        Toast('请输入正确的手机号码！');
        return;
      }
      if(!this.isAgreen) {
        Toast('请点击下方同意授权手机号码进行用户身份验证');
        return;
      }
      await this.ajaxSendCode();
      let TIME_COUNT = 120;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.dis = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.dis = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    async ajaxSendCode() {
      // 获取分类列表
      let {data} = await this.$ajax.gateway("/api/api_send_sms", {
        "body": {
          "mobile": this.userMobile
        }
      });
      if(data) {
        Toast('验证码已发送，请注意查收！');
      }
    },
    async ajaxLogin() {
      let {data,code } = await this.$ajax.gateway("/api/api_check_sms", {
        ERROR_BACK:true,
        "body": {
          "phone": this.userMobile,
          "code":this.userCode
        }
      });
      if(data && data.token){
        Toast('登录成功！');
        // Cookies.set('h5_hdbank_test_token', data.token);
        Cookies.set('admin_hdbank_test_token', data.token);
        setTimeout(() => {
          this.$router.push("/categoryList")
        }, 800);
      }
      if( code!=200){
        Toast(data.message);
      }
    },

  }
};
</script>

<style lang="less" scoped>
@import "../status/css/page.less";
.login-wrapper {
  background: #A2191D url("../status/img/jk_bg.png") no-repeat top center;
  background-size: 100% auto;
  min-height: 88vh;
  padding-top: 12vh;
  ._img {
    img {
      display: block;
      width: 100%;
    }
  }
}


._login_form {
  padding: 0.3rem 0;
  ._window {
    margin: 0 0.7rem;
    padding: 0.8rem 0.8rem 0.4rem;
    background: url("../status/img/dialog_bg.png") repeat-x left center;
    background-size: auto 100%;
    border: 3px solid #CCAD8A;
    border-radius: 0.2rem;
    min-height: 20vh;
    max-height: 70vh;
    overflow-y: scroll;
    img {
      max-width: 100%;
    }

    ._title {
      margin-bottom: 1rem;
    }
    ._form_item {
      color: #c8b489;
      text-align: center;
      border: 2px solid #CCAD8A;
      border-radius: 0.2rem;
      padding: 0.2rem;
      line-height: 0.8rem;
      margin-top: 0.1rem;
      font-size: 0.36rem;
      span._send {
        display: block;
        background: #CCAD8A;
        border-radius: 0.1rem;
        color: #a71b1e;
        font-weight: 400;
        font-size: 0.4rem;
      }
      ._input {
        background: none;
        border: 0;
        width: 94%;
        padding: 0 3%;
        font-size: 0.48rem;
        font-weight: 800;
        color: #D0C8A2;
      }
      ._input::placeholder {
        font-size: 0.44rem;
        font-weight: 100;
        color: #D0C8A2;
        opacity: 0.7;
      }
    }
    ._btn {
      text-align: center;
      padding: 0.4rem 0 0;
      img {
        max-width: 110%;
        margin-left: -5%;
      }
    }

    &._window_w {
      padding: 0.2rem;
      ._title {
        padding-top: 0.3rem;
        margin-bottom: 0;
      }
    }
  }
  ._closed {
    padding: 0.4rem 0;
    text-align: center;
    img {
      width: 1.2rem;
    }
  }
}
</style>
